"use client";

/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useState, KeyboardEvent } from "react";

interface Props {
  children: ReactElement;
  expanded?: boolean;
  className?: string;
  expandClass?: string;
  maxHeightClass?: string; // Optional prop for dynamic max-height
  allowCollapse?: boolean;
}

export default function ExpandableElement({
  expanded: isExpanded = false,
  expandClass = "max-h-24",
  maxHeightClass = "", // A reasonably high value
  className = "",
  allowCollapse = true,
  children,
}: Props) {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if ((allowCollapse && event.key === "Enter") || event.key === " ") {
      setExpanded(!expanded);
    }
  };

  const dynamicProps = allowCollapse
    ? {
        onClick: () => (allowCollapse ? setExpanded(!expanded) : false),
        onKeyDown: handleKeyDown,
        role: "button",
        tabIndex: 0,
        "aria-expanded": expanded,
      }
    : {};

  return (
    <div className="relative group" {...dynamicProps}>
      <div
        className={`prose dark:prose-invert transition-all duration-500 ease-in-out overflow-hidden   ${
          expanded ? ` ${maxHeightClass}` : `${expandClass} fade-bottom-sm`
        } ${!expanded && "hover:opacity-80 transition-all"} ${className}`}
      >
        {children}
      </div>
    </div>
  );
}
