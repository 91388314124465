/* eslint-disable import/no-cycle */
import { isPrimaryContribution } from "lib/contributions";
import { BookSeriesType, BookType, EditionType, SeriesType } from "types";
import { roundRating } from "lib/numberUtils";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import isNull from "lodash/isNull";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import pluralize from "./pluralize";

export function secondsFormat(
  seconds: number,
  options?: { showSeconds: boolean }
) {
  const parts = [];
  const hours = Math.floor(seconds / 3600);
  const hourSecondsUsed = hours * 3600;
  if (hours > 0) {
    parts.push(`${hours}h`);
  }

  const minutes = Math.round((seconds - hourSecondsUsed) / 60);
  const minuteSecondsUsed = minutes * 60;
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }

  if (options?.showSeconds) {
    const remainingSeconds = Math.round(
      seconds - hourSecondsUsed - minuteSecondsUsed
    );
    if (remainingSeconds > 0) {
      parts.push(`${remainingSeconds}s`);
    }
  }

  return parts.join(" ");
}

export function genereateBookTitle(book: BookType) {
  const primaryContributions = book.contributions.filter((contribution) =>
    isPrimaryContribution(contribution)
  );
  const title = `${book.title} by ${primaryContributions
    .map((contribution) => contribution.author.name)
    .join(" and ")}`;
  return title;
}

export function generateFeaturedBookSeries(book: BookType) {
  const bookSerieses = book.bookSeries;

  const featuredSeries =
    bookSerieses.find((bookSeries) => bookSeries.featured) || bookSerieses[0];

  if (!featuredSeries) {
    return null;
  }

  let series = "";

  if (!isNull(featuredSeries.position)) {
    if (book.compilation) {
      series = `#${featuredSeries.details || featuredSeries.position}`;
    } else {
      series = `#${featuredSeries.position}`;
    }
  }

  if (featuredSeries.series.primaryBooksCount) {
    if (!isNull(featuredSeries.position)) {
      series = `${series} of ${featuredSeries.series.primaryBooksCount.toLocaleString()}`;
    } else {
      series = featuredSeries.series.primaryBooksCount.toLocaleString();
    }
  }

  if (!isNull(featuredSeries.position)) {
    series = `${series} in`;
  }

  series = `${series} ${featuredSeries.series.name}`;
  return series;
}

const DESCRIPTION_LENGTH = 80;
export function genereateBookDescription(book: BookType) {
  const title = genereateBookTitle(book);
  const data = [title];
  const featuredSeries = generateFeaturedBookSeries(book);
  if (featuredSeries) {
    data.push(featuredSeries);
  }
  if (book.rating) {
    data.push(`${roundRating(book.rating, 2)}⭐`);
  }
  if (book.ratingsCount > 0) {
    data.push(
      `${book.ratingsCount.toLocaleString()} ${pluralize(
        "rating",
        book.ratingsCount
      )}`
    );
  }
  if (book.reviewsCount > 0) {
    data.push(
      `${book.reviewsCount.toLocaleString()} ${pluralize(
        "review",
        book.reviewsCount
      )}`
    );
  }
  if (book.readCount > 0) {
    data.push(
      `${book.readCount.toLocaleString()} ${pluralize(
        "reader",
        book.readCount
      )}`
    );
  }
  if (book.pages > 0) {
    data.push(
      `${book.pages.toLocaleString()} ${pluralize("page", book.pages)}`
    );
  }

  const description =
    book.description?.length > DESCRIPTION_LENGTH
      ? `${book.description.slice(0, DESCRIPTION_LENGTH)}...`
      : book.description;

  if (description?.length > 0) {
    data.push(description);
  }
  return data.join(". ");
}

export function isPrimaryBook(bookSeries: BookSeriesType) {
  if (bookSeries.position > 0) {
    return bookSeries.position % 1 === 0;
  }
  return false;
}

export function isBookReleased(book: BookType | EditionType) {
  if (!book.releaseYear) {
    return true;
  }
  const now = new Date();
  const thisYear = now.getFullYear();
  if (book.releaseYear > now.getFullYear()) {
    return false;
  }

  if (book.releaseYear && book.releaseYear !== thisYear) {
    return thisYear > book.releaseYear;
  }

  if (book.releaseDate) {
    const releaseDate = new Date(book.releaseDate);
    return now.getTime() >= releaseDate.getTime();
  }

  return false;
}

export function uniqBooksInSeries(series: SeriesType) {
  const bookSeriesByUsers = sortBy(
    series.bookSeries,
    (bs) => bs.book.usersCount * -1
  );
  const uniqueBookSeries = uniqBy(
    bookSeriesByUsers,
    (bs) =>
      `${
        (bs.details?.length > 0 ? bs.details : false) ||
        bs.position ||
        bs.book.id
      }`
  );

  const bookSeriesByPopularity = sortBy(
    uniqueBookSeries,
    (bs) => (isNull(bs.position) ? bs.book.title : bs.position) // Move position-less books to the bottom
  );
  return bookSeriesByPopularity;
}

// Sorts by position, then date
export function sortByPosition(bookSeries: BookSeriesType[]) {
  return bookSeries.sort((x, y) => {
    // Push null position books to the back
    if (isNull(x.position)) {
      return 1;
    }
    if (isNull(y.position)) {
      return -1;
    }
    // sort by date if positions are the same
    if (x.position === y.position) {
      if (x.book.releaseDate && y.book.releaseDate) {
        return new Date(x.book.releaseDate) < new Date(y.book.releaseDate)
          ? -1
          : 1;
      }
      if (x.book.releaseYear && y.book.releaseYear) {
        return x.book.releaseYear < y.book.releaseYear ? -1 : 1;
      }
      return 0;
    }

    return x.position - y.position;
  });
}

export function filterCompilations(
  series: SeriesType,
  bookSeries: BookSeriesType[],
  isCompilation = false
) {
  const nonCollectionBooks = bookSeries.filter((bs) => {
    const hasRange = (bs.details || "").indexOf("-") !== -1;
    const hasSeparator = (bs.details || "").indexOf(",") !== -1;

    // Return false for compilations
    if (bs.book.compilation && isCompilation && (hasRange || hasSeparator)) {
      return true;
    }

    if (!bs.details || bs.details.length === 0) {
      return true; // Can't tell
    }

    const show = isCompilation
      ? hasRange || hasSeparator
      : !(hasRange || hasSeparator);
    return show;
  });

  return sortByPosition(nonCollectionBooks);
}

export function mostPopular(bookSeries: BookSeriesType[]) {
  const maxBs = maxBy(bookSeries, (bs) => {
    return bs.position ? bs.book.usersCount : 0;
  });

  return maxBs ? maxBs.book.id : null;
}

export function findFirstPublished(bookSeries: BookSeriesType[]) {
  const firstPub = minBy(bookSeries, (bs) => {
    if (bs.position > 0) {
      if (bs.book.releaseDate) {
        return new Date(bs.book.releaseDate);
      }
      if (bs.book.releaseYear) {
        const day = bs.position ? bs.position : 31;
        return new Date(`${bs.book.releaseYear}-01-${day}T00:00:00`);
      }
    }

    return new Date();
  });

  return firstPub ? firstPub.book.id : null;
}
