"use client";

import Icon from "hardcover-ui/components/Icon";
import faHeartOutline from "icons/regular/heart.svg";
import { uiActions } from "store/ui/uiSlice";
import { useDispatch } from "react-redux";
import LikeableType from "types/LikeableType";
import Button from "hardcover-ui/components/Button";
import LoggedOutItemLikes from "./LoggedOutItemLikes";

export default function LoggedOutLikeButton({
  showLikes,
  likeable,
}: {
  showLikes: boolean;
  likeable: LikeableType;
}) {
  const dispatch = useDispatch();

  const showLogin = (e): void => {
    e.preventDefault();
    dispatch(uiActions.toggleLoginVisible("Login"));
  };

  return (
    <div className="flex items-center space-x-4" style={{ minHeight: "24px" }}>
      <Button
        aria-label="Login to like"
        onClick={showLogin}
        className="text-stone-700 dark:text-gray-300"
      >
        <Icon icon={faHeartOutline} size="lg" />
      </Button>

      <LoggedOutItemLikes showLikes={showLikes} likeable={likeable} />
    </div>
  );
}
