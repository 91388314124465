/* eslint-disable react/jsx-props-no-spreading */
import classNames from "lib/classNames";
import Link from "next/link";
import {
  TextLinkThemeProps,
  textLinkTheme,
} from "hardcover-ui/theme/components/text";
import { AnchorHTMLAttributes, PropsWithChildren, forwardRef } from "react";

type Props = {
  size?: TextLinkThemeProps["size"];
  external?: boolean;
  variant?: TextLinkThemeProps["variant"];
  underline?: boolean;
  prefetch?: boolean;
} & Partial<AnchorHTMLAttributes<HTMLAnchorElement>>;

const TextLink = forwardRef<HTMLAnchorElement, PropsWithChildren<Props>>(
  (
    {
      size = "md",
      external = false,
      variant = "normal",
      children,
      underline = true,
      className,
      prefetch,
      ...rest
    },
    ref
  ) => {
    const finalClassName = classNames(
      textLinkTheme.variant[variant],
      textLinkTheme.size[size],
      underline
        ? "underline hover:no-underline decoration-gray-300 dark:decoration-gray-500"
        : "no-underline hover:underline decoration-gray-300 dark:decoration-gray-500",
      className
    );

    const props = {
      ref,
      prefetch,
      className: finalClassName,
      ...rest,
    };

    if (external) {
      return <a {...props}>{children}</a>;
    }

    return (
      <Link href={props.href} {...props}>
        {children}
      </Link>
    );
  }
);

export default TextLink;
