"use client";

import { Suspense, lazy, useState } from "react";
import pluralize from "lib/pluralize";
import Avatar from "hardcover-ui/components/Avatar";
import LikeableType from "types/LikeableType";

const LikesModal = lazy(() => import("components/likes/LikesModal"));

export default function LoggedOutItemLikes({
  showLikes,
  likeable,
}: {
  showLikes: boolean;
  likeable: LikeableType;
}) {
  const [showModal, setShowModal] = useState(false);

  if (likeable.likesCount === 0) {
    return false;
  }

  return (
    <div className="flex flex-row">
      <button
        type="button"
        className="hover:underline text-sm"
        onClick={() => setShowModal(true)}
      >
        {likeable.likesCount.toLocaleString()}{" "}
        {pluralize("like", likeable.likesCount)}
      </button>
      {showLikes && (
        <div className="flex flex-row -space-x-2 ml-2">
          {likeable.likes.map((activityLike) => (
            <Avatar
              key={`like-${activityLike.userId}-${activityLike.likeableId}-${activityLike.likeableType}`}
              image={activityLike.user.cachedImage}
              size="sm"
              alt={`@${activityLike.user.username}`}
            />
          ))}
        </div>
      )}
      {showModal && (
        <Suspense>
          <LikesModal
            open={showModal}
            likeable={likeable}
            onClose={() => setShowModal(false)}
          />
        </Suspense>
      )}
    </div>
  );
}
