"use client";

import Icon from "hardcover-ui/components/Icon";
import faEye from "icons/solid/eye.svg";
import faUserFriends from "icons/solid/users.svg";
import { IconThemeProps } from "hardcover-ui/theme/components/icon";

export interface PrivacyIconProps {
  privacySettingId: number;
  className?: string;
  size?: IconThemeProps["size"];
}

export default function PrivacyIcon({
  privacySettingId,
  className = "text-indigo-500 dark:text-yellow-400",
  size = "lg",
}: PrivacyIconProps) {
  if (privacySettingId === 1) {
    return <></>;
  }
  return (
    <>
      <span className={className}>
        <Icon
          icon={privacySettingId === 2 ? faUserFriends : faEye}
          size={size}
          data-id="tooltip"
          data-tooltip-content={
            privacySettingId === 2
              ? "People you follow can see this."
              : "Only you can see this."
          }
        />
      </span>
    </>
  );
}
