/* eslint-disable import/no-cycle */
import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import { SeriesType } from "types";
import ContributionType from "types/ContributionType";
import { uniqBooksInSeries } from "./bookUtils";

const primaryContributions = ["translator", "author"];
export const isPrimaryContribution = (contribution: ContributionType) => {
  if (!contribution.contribution || contribution.contribution.length === 0) {
    return true;
  }

  return (
    primaryContributions.indexOf(contribution.contribution.toLowerCase()) !== -1
  );
};

export function primaryContributionNames(
  contributions: ContributionType[]
): string[] {
  const filteredContributions = contributions.filter((c) =>
    isPrimaryContribution(c)
  );
  return filteredContributions.map((c) => c.author.name);
}

const authorContributions = ["author"];
export const isAuthor = (contribution: ContributionType) => {
  if (!contribution.contribution || contribution.contribution.length === 0) {
    return true;
  }

  return (
    authorContributions.indexOf(contribution.contribution.toLowerCase()) !== -1
  );
};

export const primaryAuthorName = (contributions: ContributionType[]) => {
  const primaryAuthors = contributions.filter((a) => isPrimaryContribution(a));

  if (primaryAuthors.length === 0) {
    return "";
  }
  return primaryAuthors[0].author.name;
};

export const primaryContributionsForSeries = (series: SeriesType) => {
  const sortedBookSeries = uniqBooksInSeries(series);

  const contributions = flatten(
    sortedBookSeries.map((bs) => bs.book.contributions)
  );
  const seriesPrimaryContributions = contributions.filter((contribution) =>
    isPrimaryContribution(contribution)
  );

  return uniqBy(
    seriesPrimaryContributions,
    (contribution) => contribution.author.slug
  );
};
