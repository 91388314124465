"use client";

import { Suspense, lazy } from "react";
import LikeableType from "types/LikeableType";
import { useSelector } from "react-redux";
import { getUserId } from "store/currentUser/currentUserSelector";
import useMount from "hooks/useMount";
import LoggedOutLikeButton from "./LoggedOutLikeButton";

const LoggedInLikeButton = lazy(() => import("./LoggedInLikeButton"));

export interface Props {
  likeable: LikeableType;
  showLikes?: boolean;
}

export default function LikeButton({ likeable, showLikes = true }) {
  const currentUserId = useSelector(getUserId);
  const mounted = useMount();

  if (!mounted || !currentUserId) {
    return <LoggedOutLikeButton showLikes={showLikes} likeable={likeable} />;
  }

  return (
    <Suspense>
      <LoggedInLikeButton
        showLikes={showLikes}
        likeable={likeable}
        userId={currentUserId}
      />
    </Suspense>
  );
}
