"use client";

import { useRef, useState } from "react";
import classNames from "lib/classNames";

export default function Spoiler({ children }) {
  const [visible, setVisible] = useState(false);
  const spoiler = useRef();
  const clicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(!visible);
  };
  const toggleClicked = (e) => {
    if (e.currentTarget === spoiler.current) {
      if (e.key === " " || e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        setVisible(!visible);
      }
    }
  };
  return (
    <span
      ref={spoiler}
      role="button"
      onClick={clicked}
      tabIndex={0}
      onKeyDown={toggleClicked}
      className={classNames(
        "spoiler cursor-pointer transition-all duration-300",
        "rounded p-0.5 dark:text-yellow-400 text-indigo-500",
        visible ? "spoiler-active bg-transparent" : "spoiler-inactive blur-sm"
      )}
    >
      {children}
    </span>
  );
}
